import { useAuth0 } from '@auth0/auth0-react'
import { useParams } from 'react-router'
import React, { ReactElement, useEffect } from 'react'

import Spinner from '../../components/spinner/Spinner'
import { useAppConfig } from '../../context/AppConfigContext'
import apiClientService from '../../utils/api'
import { history } from '../../utils/historyStore'
import { SpinnerContainer } from '../showroom/vehicle-list/VehicleList.styled'
import { cleanSessionStorage } from '../vehicle-configurator/util/sessionStorageUtil'

const Logout = (): ReactElement => {
  const { logout, isAuthenticated, isLoading } = useAuth0()
  const { auth } = useAppConfig()
  const { locale } = useParams<{ locale: string }>()

  useEffect(() => {
    const performLogout = () => {
      apiClientService.logout(locale).finally(() => {
        localStorage.removeItem('welcomeMessage')
        cleanSessionStorage([])
        logout({
          clientId: auth?.clientId,
          logoutParams: {
            returnTo: `${window.location.origin}?enforceLogin=true`,
          },
        })
      })
    }

    if (!isLoading) {
      if (isAuthenticated) {
        performLogout()
      } else {
        history.push('/')
      }
    }
  }, [isLoading, isAuthenticated, logout, auth?.clientId, locale])

  return (
    <SpinnerContainer>
      <Spinner size={20} />
    </SpinnerContainer>
  )
}

export default Logout
