import Card from '@material-ui/core/Card'
import { CloseIcon } from '@velocity/ui/draft'
import { FC, useState } from 'react'
import styled from 'styled-components'

import { Color } from '../../utils/color'
import { handleEnter } from '../../utils/handleEnter'
import { getStyles, isRebranding } from '../../utils/styles'

interface Props {
  message?: string
}

const s = () => getStyles().colors.configurator.moreInfo.drawer

const isMessageSetInLocalStorage = (msg: string) => {
  return localStorage.getItem('welcomeMessage') === msg
}

const WelcomeMessage: FC<Props> = ({ message }) => {
  const [messageSeen, setMessageSeen] = useState<boolean>(
    isMessageSetInLocalStorage(message),
  )
  if (!message || messageSeen) return null

  const handleClose = () => {
    localStorage.setItem('welcomeMessage', message)
    setMessageSeen(true)
  }

  return isRebranding() ? (
    <AlertCardAyvens data-e2e-id="WelcomeMessage">
      <div dangerouslySetInnerHTML={{ __html: message }} />
      <CloseButton
        data-testid="close-message-button"
        size="xs"
        color={s().topCloseButton}
        tabIndex={0}
        onClick={handleClose}
        onKeyPress={(e) => handleEnter(e.key, handleClose)}
      />
    </AlertCardAyvens>
  ) : (
    <AlertCardLP data-e2e-id="WelcomeMessage">
      <span dangerouslySetInnerHTML={{ __html: message }} />
      <CloseButton
        data-testid="close-message-button"
        size="xs"
        color={s().topCloseButton}
        tabIndex={0}
        onClick={handleClose}
        onKeyPress={(e) => handleEnter(e.key, handleClose)}
      />
    </AlertCardLP>
  )
}

const AlertCardLP = styled(Card)`
  width: 100%;
  margin: 8px 0px;
  padding: 10px;
  background-color: ${Color.orange.orange50} !important;
  border: 1px solid ${Color.orange.orange500} !important;
  display: flex;
  justify-content: space-between;
`

const AlertCardAyvens = styled(Card)`
  width: 100%;
  margin: 8px 0px;
  padding: 10px;
  background-color: ${getStyles().colors.velocity.infoNotice
    .background} !important;
  border: 1px solid ${getStyles().colors.velocity.infoNotice.borderLeft} !important;
  display: flex;
  justify-content: space-between;
`

export const CloseButton = styled(CloseIcon)`
  cursor: pointer;
  transform: scale(1.4);
  opacity: 0.3;
  margin-top: 3px;

  &:focus,
  &:hover {
    border: 2px solid #000;
  }
`

export default WelcomeMessage
