import { Box, Container, Grid, Typography } from '@material-ui/core'
import { sanitize } from 'dompurify'
import { useTranslation } from 'react-i18next'
import React, { ReactElement } from 'react'
import styled from 'styled-components'

import { DICTIONARY } from '../../constants/dictionary'
import { getStyles } from '../../utils/styles'

interface Props {
  component: React.ReactNode
}

const TitleStyled = styled(Typography)<Props>`
  font-size: 50px;
  font-weight: 700;
  color: ${getStyles().colors.scheduledMaintenance.font};
`

const SubTitleStyled = styled(Typography)<Props>`
  font-size: 20px;
  font-weight: 200;
  margin-top: 24px;
`

const ScheduledMaintenance = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <Container>
      <Grid container={true} alignItems="center" direction="row">
        <Grid item={true} xs={12}>
          <Box mt={16} textAlign="left">
            <TitleStyled
              component="h1"
              data-testid="scheduled-maintenance-title"
            >
              {t(DICTIONARY.SCHEDULED_MAINTENANCE_TITLE)}
            </TitleStyled>
            <SubTitleStyled
              component="h2"
              data-testid="scheduled-maintenance-message"
              dangerouslySetInnerHTML={{
                __html: sanitize(t(DICTIONARY.SCHEDULED_MAINTENANCE_MESSAGE)),
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ScheduledMaintenance
